.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}





.dropdown-menu-float {
  max-height: 280px;
  overflow-y: auto;
}



#parent::before {
	background-image: url('https://images.unsplash.com/photo-1546527868-ccb7ee7dfa6a');
	background-size: cover;
	position: absolute;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
	opacity: 0.6;
	content: "";
}

#parent {

	position: relative;
	height: 100vh;
}

#child { /* #child CSS is purely for styling */ 
 	font-family: sans-serif;
 	font-size: 60px;
 	color: purple;
 	text-align: center;
 	position: relative;
 	top: 50%;
 	left: 50%;
 	transform: translate(-50%, -50%);
}






#my_parent::before {
	
	background-size:cover;
  background-repeat: no-repeat;
	position: absolute;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
	opacity: 0.6;
	content: "";
  height: 300px;
  background-color: black;
}

#my_parent {

	position: relative;
  height: 300px ;
  background-color: black;
 
}

#my_child { /* #child CSS is purely for styling */ 
 	font-family: sans-serif;
 	font-size: 60px;
 	color: black;
 	text-align: center;
 	position: relative;
 	top: 50px;
 	left: 50%;
 	transform: translate(-50%, -50%);
 
}















.flex-container_course {
  padding: 0;
  margin: 0;
  list-style: none;

  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: right;
  justify-content: right;
}

row_course {
  width: 100%;
}

.flex-item_course {
 
  padding: 5px;
  width: 200px;
  height: 50px;
  

 
  color: black;
  font-weight: bold;
  font-size: 3em;
  text-align: right;
}




.headerDivider {
  border-left:1px solid white; 
  border-right:1px solid white; 
  height:60px;
  margin: 15px;
 
}